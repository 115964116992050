import React, { useEffect, useState } from 'react';
import './GymStatus.css';
import { ReactComponent as Logo } from './Artemida_SVG.svg'; // Import the SVG as a component

const GymStatus: React.FC = () => {
  const [peopleCount, setPeopleCount] = useState<number>(0);

  useEffect(() => {
    // Fetch people count from the specified endpoint
    const fetchPeopleCount = async () => {
      try {
        const response = await fetch('https://api.artemidafitness.com/api/peopleCount');
        const data = await response.json();
        // Update state with the correct property from the API response
        setPeopleCount(data.peopleCount);
      } catch (error) {
        console.error('Error fetching people count:', error);
      }
    };

    fetchPeopleCount();
  }, []);

  return (
    <div className="gym-status">
      <div className="header">
        {/* Render SVG directly as a component */}
        <div className="logo">
          <Logo />
        </div>
      </div>
      <div className="content">
        <div className="status-text">Trenutno ljudi u teretani:</div>
        <div className="people-count">{peopleCount}/35</div>
      </div>
    </div>
  );
};

export default GymStatus;
